/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				if ( $( "#mememe" ).length ) {	
				$(function() {

			
				var $sidebar   = $("#mememe"), 
					$window    = $(window),
					offset     = $sidebar.offset(),
					topPadding = 100;
			
				$window.scroll(function() {
					if ($window.scrollTop() > offset.top) {
						$sidebar.stop().animate({
							marginTop: $window.scrollTop() - offset.top + topPadding
						});
					} else {
						$sidebar.stop().animate({
							marginTop: 0
						});
					}
				});
				
			});
				}

				$.stellar();

				$('#carousel-programs').carousel({
					interval: 6000,
				});

				$('.nav-collapse .active').parents('.panel-collapse.collapse').addClass('in');
				$('.nav-collapse .active').parents('.panel').find('.panel-heading a').removeClass('collapsed');

				var waypoint = new Waypoint({
					element: document.getElementById('main'),
					handler: function(direction) {
						if( direction === 'down') {
								$('.navbar').addClass('offScreen');
						}
						else if( direction === 'up') {
								$('.navbar').removeClass('offScreen');
						}
					}
				});
				
				// Get browser height to know when to make fixed nav scroll (fix for iphones)
				browserHeight = $( window ).height();
				$('.navbar-collapse').css("max-height", browserHeight);

				// Popup window for social buttons
				var popupWindow = null;
				function centeredPopup(url,winName,w,h,scroll) {
						LeftPosition = (screen.width) ? (screen.width-w)/2 : 0;
						TopPosition = (screen.height) ? (screen.height-h)/2 : 0;
						settings = 'height='+h+',width='+w+',top='+TopPosition+',left='+LeftPosition+',scrollbars='+scroll+',resizable';
						popupWindow = window.open(url,winName,settings);
				}

				$('.share .btn').click(function() {
					centeredPopup($(this).attr('href'),'Test','700','300','yes');
					return false;
				});

				// Google Number Tracking Init (Toll Free & Local)
				// var callback1 = function(formatted_number, mobile_number) {
			 //      var e = document.getElementById("number_link1");
			 //      e.href = "tel:" + mobile_number;
			 //      e.innerHTML = "";
			 //      e.appendChild(document.createTextNode(formatted_number));
			 //    };
		  //   	var callback2 = function(formatted_number, mobile_number) {
		  //         var e = document.getElementById("number_link2");
		  //         e.href = "tel:" + mobile_number;
		  //         e.innerHTML = "";
		  //         e.appendChild(document.createTextNode(formatted_number));
		  //       };

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				$('.carousel').carousel({
					interval: 6000
				});

				$('.programs section').onScreen({
						doIn: function() {
								$(this).addClass('on');
						},
						tolerance: 100,
						throttle: 50,
				});

				$('.news figure').onScreen({
						doIn: function() {
								$(this).addClass('on');
						},
						tolerance: 100,
						throttle: 50,
				});
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// Programs page
		'programs': {
			init: function() {
				
				$('a[href$="programs"]:first').removeClass("collapsed");
				$('a[href$="programs"]:first').attr("aria-expanded", "true");
				$('#programs').addClass("in");
				$('#programs').attr("aria-expanded", "true");
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// Contact
		'contact': {
			init: function() {
				//initalize google maps api
				function initialize() {
						var myLatlng = new google.maps.LatLng(25.097773,-77.2738088);

						var mapOptions = {
								center: myLatlng,
								zoom: 12,
								scrollwheel: false,
						};

						var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

						var contentString = '<div id="bodyContent" style="margin-top: 10px;">'+
								'<img src="/wp-content/themes/sage/dist/images/dolphin-encounters.png" width="75" />'+
								'</div>';

						var infowindow = new google.maps.InfoWindow({
								content: contentString,
						});

						var marker = new google.maps.Marker({
								position: myLatlng,
								map: map,
								animation: google.maps.Animation.DROP,
						});

						google.maps.event.addListener(map, 'tilesloaded', function() {
								infowindow.open(map,marker);
						});						
				}

				google.maps.event.addDomListener(window, 'load', initialize);
			}
		},
		// Contact
		'local_weather': {
			init: function() {
				//initalize google maps api
				function initialize() {
						var myLatlng = new google.maps.LatLng(25.097773,-77.2738088);

						var mapOptions = {
								center: myLatlng,
								zoom: 8,
								scrollwheel: false
						};

						var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

						var marker = new google.maps.Marker({
								position: myLatlng,
								map: map,
								animation: google.maps.Animation.DROP,
						});

						google.maps.event.addListener(map, 'tilesloaded', function() {
								infowindow.open(map,marker);
						});						
				}

				google.maps.event.addDomListener(window, 'load', initialize);
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
